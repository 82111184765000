import React from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import { Link } from 'gatsby'

const NotFound = () => (
    <Layout title="Error">
        <PageBanner />
        <div className='not-found-area ptb-100'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='not-found-content text-center'>
                            <h1>404</h1>
                            <h2>Página no encontrada</h2>
                            <p>Lo sentimos, pero la página que estás buscando no se ha encontrado.</p>
                            <Link to='/'>Volver a la página principal</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default NotFound